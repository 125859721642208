<template>
    <v-card id="top" class="pa-5 custom-border border">
        <NominationDialog :dialog="dialog" :course="selected" @close="dialog = false" @save="save" :nominationLoading="nominationLoading"/>
        <span class="poppins fw600 mb-10">
            <div class="fixed poppins f22 fw600 pb-0 mx-2">
                <v-badge
                    :content="cart.length"
                    :color="cart.length === 0 ? 'transparent' : 'primary'"
                    :offset-x="-4"
                >
                    My List
                </v-badge>
            </div>
            <v-sheet height="auto" max-height="60vh" v-if="cart.length>0" class="d-flex flex-column overflow-y-auto px-1 my-4 ">
                <v-card v-if="cart.length > 0" v-for="(item, i) in cart" :key="item.uuid" class="my-2 d-flex flex-row justify-space-between custom-border" elevation="2">
                    <section>
                        <v-img
                            alt="course image"
                            width="80"
                            height="150"
                            container
                            :src="item.image ? item.image.url : require('@/assets/images/landing/background/default-course-bg.png')"
                            :lazy-src="require('@/assets/images/landing/background/default-course-bg.png')"
                            v-on:error="item.image = null"
                            class="rounded-l cursor-pointer"
                            @click="$router.push({name: 'User Other Course Details', params: { uuid: item.uuid}, query: { timestamp: Date.now() }})"/>
                    </section>
                    <section class="d-flex flex-column flex-grow-1 cursor-pointer">
                        <div @click="$router.push({name: 'User Other Course Details', params: { uuid: item.uuid}, query: { timestamp: Date.now() }})" class=" f16 poppins fw600 truncate d-inline-block mx-2 my-2 mb-2" style="width: 37vw; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                            {{ item.title ? item.title : 'Untitled'}}
                        </div>
                        <div @click="$router.push({name: 'User Other Course Details', params: { uuid: item.uuid}, query: { timestamp: Date.now() }})" v-if="item.available_until" class="primary-font fw600 fw500 f12 py-0 mx-2">
                            <span v-if="item.available_until.start_date"> from {{ $dateFormat.mmDDyy(item.available_until.start_date) }} </span>
                            <span v-if="item.available_until.end_date"> until {{ $dateFormat.mmDDyy(item.available_until.end_date) }} </span>
                        </div>
                        <div @click="$router.push({name: 'User Other Course Details', params: { uuid: item.uuid}, query: { timestamp: Date.now() }})" class="f16 fw600 primary--text mx-2" v-if="item.is_free">FREE!</div>
                        <div @click="$router.push({name: 'User Other Course Details', params: { uuid: item.uuid}, query: { timestamp: Date.now() }})" class="f16 fw600 primary--text mx-2" v-if="!item.is_free">PHP {{ item.price ? item.price : 0 }}</div>
                        <section class="d-flex flex-row align-center justify-end mt-1">
                            <v-alert color="secondary-2" class="f12" text dense v-if="!forRegistration(item)">
                                Registration already closed
                            </v-alert>
                            <v-btn dense 
                                v-if="forRegistration(item) && user_courses.findIndex(_course => _course.uuid === item.uuid) === -1"
                                color="primary"
                                small
                                class="poppins f09 float-right" 
                                @click="selected = item, item.is_free === 1 ? proceedToPayment() : (item.price <= 0 ? proceedToPayment() : dialog = true)"
                                :loading="checkoutLoading[i]"
                                >Proceed to Payment</v-btn>
                            <v-alert color="success" class="f12" text dense v-if="forRegistration(item) && user_courses.findIndex(_course => _course.uuid === item.uuid) !== -1">
                                ENROLLED
                            </v-alert>
                        </section>
                    </section>
                    <v-btn icon dense small @click="removeFromList(item.uuid, item.id)" class="secondary-2--text poppins pa-0 my-0 text-capitalize f10">
                        <v-icon > mdi-close-circle </v-icon>
                    </v-btn>
                </v-card>
            </v-sheet>
            <!-- <v-row>
                <v-col cols="12" md="7" class="d-flex flex-row">
                    <v-img
                        max-height="100"
                        max-width="80"
                        class="mr-2 align-self-start cursor-pointer"
                        :src="item.image ? item.image.url : require('@/assets/images/landing/background/default-course-bg.png')"
                        :lazy-src="item.image ? item.image.url : require('@/assets/images/landing/background/default-course-bg.png')"
                        v-on:error="require('@/assets/images/landing/background/default-course-bg.png')"
                        @click="$router.push({name: 'User Other Course Details', params: { uuid: item.uuid}, query: { timestamp: Date.now() }})"/>
                    <v-container class="d-flex pa-0 flex-column align-start">
                        <v-container class="d-flex flex-column align-start cursor-pointer pa-0"
                            @click="$router.push({name: 'User Other Course Details', params: { uuid: item.uuid}, query: { timestamp: Date.now() }})">
                            <section class="poppins fw600">
                                <div class="truncated">
                                    {{ truncateDescription(item.title, 40) }}
                                </div>
                                <div v-if="item.available_until" class="primary-font fw500 f12 py-0">
                                    <span v-if="item.available_until.start_date"> from {{ $dateFormat.mmDDyy(item.available_until.start_date) }} </span>
                                    <span v-if="item.available_until.end_date"> until {{ $dateFormat.mmDDyy(item.available_until.end_date) }} </span>
                                </div>
                            </section>
                        </v-container>
                        <v-btn text dense @click="removeFromList(item.uuid, item.id)" class="danger--text poppins pa-0 my-0 text-capitalize f10">
                            <v-icon small> mdi-close </v-icon>
                            Remove
                        </v-btn>
                    </v-container>
                </v-col>
                <v-col cols="12" md="2">
                    <span class="f16 fw600" v-if="item.is_free">FREE!</span>
                    <span class="f16 fw600" v-if="!item.is_free">PHP {{ item.price ? item.price : 0 }}</span>
                </v-col>
                <v-col cols="12" md="3">
                    <v-btn color="primary" class="f12" @click="proceedToPayment(item.id, i)" :loading="checkoutLoading[i]">Proceed to Payment</v-btn>
                </v-col>
            </v-row> -->
            <section class="" v-else>
                <span class="f14 secondary-2--text mx-2"><i>Your list is empty</i></span>
            </section>
        </span>
        <!-- <div class="poppins f18 fw600 mt-5 mb-2" v-if="other_courses.length > 0">
            <span class="poppins f18 fw600">
                Add More Courses
            </span>
            <v-slide-group
                show-arrows
                class="mx-2"
                v-if="!$vuetify.breakpoint.mobile"
                >
                <v-slide-item v-for="course in other_courses" :key="course.id">
                    <GalleryCard class="ma-1" :course="course" />
                </v-slide-item>
            </v-slide-group>
            <section class="d-flex flex-column justify-center mx-2">
                <ListCards v-for="course in other_courses" :key="course.id" :course="course" class="my-2"/>
            </section>
        </div> -->

    </v-card>
</template>

<style scoped>
.v-progress-linear {
    width: 180px !important;
    margin-top: 25vh !important;
}
</style>

<script>
import { mapActions, mapState, mapMutations } from "vuex"
import GalleryCard from "../../../components/user/course/GalleryCard.vue"
import ListCards from '../../../components/user/course/ListCard.vue'
import NominationDialog from "@/components/user/course/tocart/NominationDialog.vue"

export default {
    components: {
        GalleryCard,
        NominationDialog,
        ListCards
    },
    data: () => ({
        dialog: false,
        loading: false,
        loading1: false,
        selected: null,
        removeLoading: false,
        checkoutLoading: {},
        errorPrompt: false,
        nomination: null,
        nominationLoading: false
    }),
    mounted() {
        //this.loading = true

        this.otherCoursesAction().then(() => {
            this.loading=false
        })
    }, 
    computed: {
        ...mapState({
            course: (state) => state.course,
            other_courses: (state) => state.other_courses,
            // courses(state) {
            //     return [...state.free_courses, ...state.paid_courses].filter(item => item.uuid !== this.$route.params.uuid)
            // }
        }),
        ...mapState('usr', {
            cart: (state) => state.cart,
            user_courses: (state) => state.courses,
        }),

        getPrice() {
            if(this.cart.length === 0) {
                return 0
            } else {
                let price = 0
                this.cart.forEach(i => {
                    if(!i.is_free) {
                        price += parseInt(i.price ? i.price : 0)
                    }
                })

                return price
            }
        }
    },
    methods: {
        ...mapActions(['otherCoursesAction']),
        ...mapMutations(['alertMutation']),
        ...mapMutations('usr',['cartMutation']),
        ...mapActions('usr', ['removeFromCart', 'getCoursesAction', 'getCartItems']),

        forRegistration(item){
            if(item.available_until) {
                let today = Date.now()
                if(item.available_until.end_date) {
                    if(today > new Date(item.available_until.end_date)) {
                        return false
                    } else {
                        return true
                    }
                } else return true
            } else return false
        },

        truncateDescription(desc, length) {
            return desc.length > length ? `${desc.slice(0, length-4)}...` : desc
        },

        removeFromList(uuid, id){
            let filtered = this.cart.filter(i => i.uuid !== uuid)
            //this.cartMutation(filtered)

            if(filtered.length === 0) {
                localStorage.removeItem('cart')
            } else {
                localStorage.setItem('cart', JSON.stringify(filtered))
            }
            this.removeFromCart({course_id: id})
        },

        save(file) {
            this.nomination = file
            this.dialog = false
            this.proceedToPayment()
        },

        proceedToPayment(){
            let _index = this.cart.findIndex(_course => _course.id === this.selected.id)
            this.checkoutLoading[_index] = true
            let _form = new FormData()

            _form.append('course_id', this.selected.id)

            if(this.nomination) {
                _form.append('nomination_form', this.nomination)
                this.nominationLoading = true
            }

            const config = { 
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                }
            }

            this.$api.post(`/user/checkout`, _form, config).then(res => {
                if (res.data.hasOwnProperty('mode_of_payment')) {
                    this.checkoutLoading[_index] = false
                    this.alertMutation({
                        show: true,
                        text: 'Enrollment Success! Redirecting you to dashboard...',
                        type: "success"
                    })
                    setTimeout(() => {
                        location.href = '/dashboard'
                    }, 1000)
                } else {
                    if(this.nomination) {
                        this.$router.push({ name: 'User Transaction History' })
                    } else {
                        var newDoc = document.implementation.createHTMLDocument();
                        var parsedContent = document.createElement('div');

                        var parser = new DOMParser();
                        var xmlDoc = parser.parseFromString(res.data, 'text/html');

                        parsedContent.appendChild(xmlDoc.documentElement);
                        newDoc.body.appendChild(parsedContent);
                        
                        var newTab = window.open();
                        newTab.document.write(newDoc.documentElement.outerHTML);

                        this.checkoutLoading[_index] = false
                        location.href = '/dashboard'
                    }
                    
                }

                this.nominationLoading = false
            }).catch(e => {
                this.nominationLoading = false
                this.dialog = false
                if(e.response.status === 404) {
                    this.checkoutLoading = {
                        ...this.checkoutLoading,
                        _index: false
                    }
                    this.alertMutation({
                        show: true,
                        text: 'Checkout is not available at this moment',
                        type: "error"
                    })
                } else {
                    this.checkoutLoading[_index] = false
                    this.alertMutation({
                        show: true,
                        text: 'Something went wrong.',
                        type: "error"
                    })

                    this.checkoutLoading = {
                        ...this.checkoutLoading,
                        _index: false
                    }
                }
            })
            
        }
    },
}
</script>